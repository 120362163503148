.hero {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100%;
    max-height: 90rem;
    max-width: 139.6rem;
    margin: auto;
    padding: 0 10rem;

    @include respondTo(tablet) {
        padding: 0 8rem;
    }

    @include respondTo(mobile) {
        height: unset;
        max-height: unset;
        padding: 6rem 6rem 0 6rem;
    }
    
    &_top, 
    &_bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        @include respondTo(mobile) {
            display: none;
        }
    }

    &_top {
        padding-top: 5rem;
    }

    &_bottom {
        padding-bottom: 5rem;
    }

    &_logo {
        display: block;
    }

    &_logo svg {
        display: block;
        height: 30rem;
        margin: auto;

        @include respondTo(tablet) {
            height: unset;
            width: 80%;
        }

        @include respondTo(mobile) {
            width: 100%;
        }
    }
}

