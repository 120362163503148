// FONTS SIZE //

.title-h1 {
    font-size: 20rem;
    font-family: $fontMigra;
    font-weight: normal;
}

.title-h2 {
    font-size: 12.5rem;
    font-family: $fontMigra;
    font-weight: normal;

    @include respondTo(tablet) {
        font-size: 10rem;
    }

    @include respondTo(mobile) {
        font-size: 6rem;
    }
}

.title-h3 {
    font-size: 5rem;
    font-family: $fontMigra;
    text-transform: uppercase;
    font-weight: normal;

    @include respondTo(tablet) {
        font-size: 4rem;
    }

    @include respondTo(mobile) {
        font-size: 2.8rem;
    }
}

.title-h4 {
    font-size: 3.2rem;
    font-family: $fontMigra;
    text-transform: uppercase;
    font-weight: normal;

    @include respondTo(tablet) {
        font-size: 3.2rem;
    }

    @include respondTo(mobile) {
        font-size: 2.8rem;
    }
}

.text-lead {
    font-size: 1.4rem;
    font-family: $fontOpenSans;
    text-transform: uppercase;
}

.body-text {
    font-size: 1.2rem;
    font-family: $fontOpenSans;
    text-align: justify;
    line-height: 1.7rem;
}

.cursor-pointer {
    cursor: pointer;
}


