.network {

    &_note {
        margin-bottom: 3rem;
    }

    &_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        @include respondTo(mobile) {
            width: 100%;
            flex-direction: row;
            align-items: center;
            margin: 0 auto 2rem auto;
        }
    }
}

.content {

    &_email,
    &_instagram,
    &_linkedin {
        color: inherit;
        text-decoration: inherit; 
        margin-top: .1rem;
    }
}