$breakpoint-mobile: 576px; 
$breakpoint-tablet: 768px; 

@mixin respondTo($media) {
    @if $media == mobile {
        @media (max-width: $breakpoint-mobile) {
            @content
        }
    } @else if $media == tablet {
        @media (max-width: $breakpoint-tablet) {
            @content
        }
    } 
}

