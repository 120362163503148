.loader {
    background-color: $background;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 3000;

    &_line {
        background-color: #E1AE5B;
        //border-top: 1px solid $fonts;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 100vw;
    }
}