.contact {
  position: relative;
  width: 100%;
  max-width: 54rem;
  margin: 20rem auto;

  @include respondTo(tablet) {
    width: 45%;
    margin: 15rem auto;
  }

  @include respondTo(mobile) {
    width: 70%;
    margin: 5rem auto;
  }

  &_number {
    //border: 2px solid red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 65rem;
    line-height: 65rem;
    color: rgba($fonts, 0.05);
    font-size: 80rem;
    letter-spacing: -7rem;
    padding: 0 5rem;
    z-index: 0;
    overflow: hidden;
    user-select: none;

    span {
      display: block;
      transform: translateX(-5%);

      @include respondTo(mobile) {
        transform: translateX(3%);
      }
    }

    @include respondTo(tablet) {
      height: 60rem;
      line-height: 60rem;
      font-size: 80rem;
    }

    @include respondTo(mobile) {
      height: 50rem;
      line-height: 50rem;
      font-size: 45rem;
      letter-spacing: -4rem;
      overflow: unset;
      margin-left: -3rem;
    }
  }

  &_informations {
    position: relative;
    z-index: 10;
  }

  &_title {
    position: relative;
    height: 13rem;
    margin-bottom: 5rem;

    @include respondTo(mobile) {
      height: 8rem;
    }
  }

  &_year {
    position: absolute;
    bottom: -15rem;
    right: 0;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $fonts;

    @include respondTo(tablet) {
      bottom: -10rem;
    }

    @include respondTo(mobile) {
      bottom: -5.5rem;
    }
  }

  &_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    @include respondTo(mobile) {
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }

  &_form {
    width: 50%;

    @include respondTo(tablet) {
      width: 40%;
    }

    @include respondTo(mobile) {
      width: 100%;
    }
  }

  &_network {
    width: 39%;

    @include respondTo(mobile) {
      margin: 2.5rem auto;
      width: 100%;
    }
  }
}
